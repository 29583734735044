<template>
  <div class="wysiwyg-wrapper py-3">
    <div v-bind:id="toolbarId">
      <select class="ql-size">
        <option value="small"></option>
        <option selected></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
      <button class="ql-bold"></button>
      <button class="ql-link"></button>
      <button class="ql-list">
        <v-icon small color="black">fas fa-list</v-icon>
      </button>
    </div>
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
export default {
  name: 'Quill',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    toolbarId() {
      return 'toolbar' + this._uid;
    },
  },
  data() {
    return {
      editor: null,
      fileManager: false,
    };
  },
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: '#' + this.toolbarId,
      },
      theme: 'snow',
    });
    this.editor.root.innerHTML = this.value;
    this.editor.on('text-change', () => this.update());
  },
  methods: {
    update() {
      this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
    },
  },
};
</script>

<style lang="scss">
@import "quill/dist/quill.core";
@import "quill/dist/quill.snow";
.ql-editor {
  height: 200px;
}
</style>

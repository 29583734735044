<template>
  <v-form ref="form" v-if="!isLoading">
    <k-field-group>
      <v-row align-items="stretch">
        <v-col v-for="(particularity , index) in particularities"
               :key="particularity.id"
               cols="12"
               sm="6"
               md="4"
               xl="3">
          <v-card v-if="!isEditMode">
            <v-card-title>{{ particularity.title }}</v-card-title>
            <v-card-subtitle>{{ particularity.updatedAt }}</v-card-subtitle>
            <v-card-text v-html="particularity.body"></v-card-text>
          </v-card>
          <v-card v-else>
            <v-btn absolute fab right="-10" top="-10" small color="primary" @click.stop="deleteParticularityByIndex(index)">
              <v-icon small>$delete</v-icon>
            </v-btn>
            <v-card-title>
              <KTextField field="title"
                          no-label
                          v-model="particularity.title"
                          :validation-field="`items.${index}.title`"/>
            </v-card-title>
            <v-card-text>
              <Quill field="body" no-label v-model="particularity.body" rows="15"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-card v-if="isEditMode" outlined class="add-particularity-card fill-height">
            <v-card-text class=" d-flex fill-height align-center justify-center">
              <v-btn outlined color="primary" @click="addParticularity">{{ $t("actions.create") }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="can('client.all')">
        <v-col>
          <v-card>
            <v-card-actions>
              <VSpacer/>
              <v-btn color="primary" @click="saveData" v-if="isEditMode" :loading="isLoadingSave">{{ $t("actions.save") }}</v-btn>
              <v-btn color="primary" @click="isEditMode = !isEditMode" v-else>{{ $t("actions.edit") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </k-field-group>
  </v-form>
  <v-row v-else>
    <v-col v-for="i in 3" :key="i" cols="12" sm="6" md="4" xl="3">
      <v-card>
        <v-card-text>
          <v-skeleton-loader type="paragraph@6"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="js">
import { getParticularities, saveParticularities } from "@/api/endpoints/clientParticularities.js";
import dayjs from "@/plugins/dayJs";
import KTextField from "@/components/crud/fields/KTextField.vue";
import Quill from "@/components/crud/fields/Quill.vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import editor from "quill/core/editor.js";
import { mapGetters } from "vuex";

export default {
  name: "ClientParticularities",
  computed: {
    ...mapGetters("profile", ["can"]),
    editor() {
      return editor
    }
  },
  components: {
    KFieldGroup,
    Quill,
    KTextField,
  },
  data: () => ({
    isLoading: true,
    isLoadingSave: false,
    particularities: [],
    isEditMode: false,
  }),
  watch: {
    "$route.params.clientId": {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const response = await getParticularities(this.$route.params.clientId);
      this.particularities = response.data.data.map((particularity) => ({
        ...particularity,
        updatedAt: dayjs(particularity.updatedAt).format("LLL"),
      }));
      this.isLoading = false;
    },
    async saveData() {
      try {
        this.isLoadingSave = true;
        await this.$refs.form.validate();
        const response = await saveParticularities(this.$route.params.clientId, {
          items: this.particularities.map((particularity) => {
            return {
              id: particularity.id,
              title: particularity.title,
              body: particularity.body,
            };
          }),
        });
        this.particularities = response.data.data.map((particularity) => ({
          ...particularity,
          updatedAt: dayjs(particularity.updatedAt).format("LLL"),
        }));
        this.isEditMode = false;
      } catch (e) {
        await this.$refs.form.validate();
      } finally {
        this.isLoadingSave = false;
      }

    },
    addParticularity() {
      this.particularities.push({
        title: "",
        body: "",
      });
    },
    deleteParticularityByIndex(index) {
      this.particularities.splice(index, 1);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-card p {
  margin-bottom: 0;
}

.add-particularity-card {
  border:     dashed 1px #cccccc;
  background: transparent;
}
</style>
